<template>
  <div>
    <SectionHeading>
      <template
        #headline
      >
        {{ $t('store_selection_main_page.headline') }}
      </template>
      <template
        #subline
      >
        {{ $t('store_selection_main_page.subline') }}
      </template>
    </SectionHeading>
    <div
      v-if="stores.length"
      class="space-y-5 relative"
    >
      <StoreTile
        v-for="store of stores"
        :key="store.id"
        :model-value="selectedStore"
        v-bind="store"
        tabindex="0"
        @update:model-value="emit('update:selectedStore', $event)"
      />
      <ValidationComponent
        :value="selectedStore?.id"
        :error-message="$t('validation.select_store')"
      />
    </div>
    <StoreTileSkeleton
      v-else
    />
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~/types/Store'

interface Props {
  stores: Array<Store>
  selectedStore: Store | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:selectedStore', value: Store): void
}

const emit = defineEmits<Emits>()
</script>
